<template>
    <div class="inner-container" v-if="isAuthorized">
        <!-- Заголовок страницы -->
        <div class="section-title forms">
            <span>{{ $t("modules.app.additional_npa.header.title") }}</span>
        </div>

        <!-- Подзаголовок страницы -->
        <div class="section-subtitle">
            {{$t('modules.app.additional_npa.header.' + titleNpa)}}
        </div>

        <loading
            :active="isFetching === true"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
            style="z-index: 10000!important;"
        />

        <b-card class="table-container">
            <b-table
                :fields="tableFields"
                :items="npaList"
            >
                <template #cell(action)="data">
                </template>

                <template #cell(title_ru)="data">
                    {{ data.item[$t("modules.app.additional_npa.lang.name")] }}
                </template>

                <template #cell(form)="data">
                    <a href="#" @click="openRule(data.item.ref)">{{data.item[$t("modules.app.additional_npa.lang.act_acceptance")]}}</a>
                </template>

                <template #cell(note)="data">
                    {{ data.item.description }}
                </template>

                <template #cell()="data">
                    <b-dropdown id="dropdown-dropleft" dropleft class="more">
                        <template v-slot:button-content>
                            <i class="icon icon-more"></i>
                        </template>
                        <template>
                            <b-dropdown-item @click="openModalAndUpdateForm(data.item)">
                                {{ $t("modules.app.additional_npa.tools.edit") }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="openModal">
                                {{ $t("modules.app.additional_npa.tools.add") }}
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </template>
            </b-table>

            <div class="table-footer">
                <div class="table-buttons">
                    <b-button variant="primary" @click="openModal" class="with-icon">
                        <i class="icon icon-plus-circle"></i>
                        {{ $t("modules.app.additional_npa.tools.add") }}
                    </b-button>
                </div>
            </div>
        </b-card>

        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            size="lg"
            :title="$t('modules.app.additional_npa.tools.add') + ' / ' + $t('modules.app.additional_npa.tools.edit')"
            @show="openModal"
            @hidden="resetModal"
            centered
            content-class="modal-forms"
        >

            <form ref="form" @submit.stop.prevent="addAndCloseNpa">
                <b-form-group
                    :label="$t('modules.app.additional_npa.modal-info.category')"
                    label-for="category-input"
                    label-align-sm="left"
                    class="form-flex"
                >
                    <b-form-select
                        v-model="currentCategory"
                        class="form-select"
                        disabled-field="notEnabled"
                        :state="validateState('currentCategoryList')"
                        aria-describedby="input-0-live-feedback"
                    >
                        <option v-for="option in categoryOptions" :value="option.value" :key="option.value">
                            {{$t('modules.app.additional_npa.header.' + option.value)}}
                        </option>
                    </b-form-select>
                    <div style="margin-top: 10px">
                        <b-button pill variant="outline-danger"
                                  v-for="(category, index) in $v.form.currentCategoryList.$model"
                                  :key="index"
                                  @click="deleteCategory(index)" active
                                  size="sm"
                        >
                            {{$t('modules.app.additional_npa.header.' + category.value)}}
                            &#10006;
                        </b-button>
                    </div>

                    <b-form-invalid-feedback
                        id="input-0-live-feedback"
                    >
                        {{ $t('modules.app.additional_npa.modal-info.required-category-msg') }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Видимость НПА"
                    style="font-weight: bold"
                    label-for="title-input"
                    label-align-sm="left"
                    class="form-flex"
                >
                    <b-form-checkbox switch
                                     v-b-tooltip.hover title="Видимость НПА"
                                     v-model="visibility"
                                     switches
                                     stacked
                                     :value="true"
                                     :unchecked-value="false"
                    >
                        {{visibility ? "Глобальный" : "Локальный"}}
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group
                    :label="$t('modules.app.additional_npa.modal-info.title')"
                    style="font-weight: bold"
                    label-for="title-input"
                    label-align-sm="left"
                    class="form-flex"
                >
                    <b-form-textarea
                        id="title-input"
                        required
                        no-resize
                        rows="3" max-rows="4"
                        class="form-input"
                        v-model="$v.form.title_ru.$model"
                        :state="validateState('title_ru')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-textarea>

                    <b-form-invalid-feedback
                        id="input-1-live-feedback"
                    >{{ $t('modules.app.additional_npa.modal-info.required-message') }}</b-form-invalid-feedback>

                </b-form-group>
                <b-form-group
                    :label="$t('modules.app.additional_npa.modal-info.title') + ' (каз)'"
                    style="font-weight: bold"
                    label-for="title-input"
                    label-align-sm="left"
                    class="form-flex"
                >
                    <b-form-textarea
                        id="title-input"
                        required
                        no-resize
                        rows="3" max-rows="4"
                        class="form-input"
                        v-model="$v.form.title_kz.$model"
                        :state="validateState('title_kz')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-textarea>

                    <b-form-invalid-feedback
                        id="input-1-live-feedback"
                    >{{ $t('modules.app.additional_npa.modal-info.required-message') }}</b-form-invalid-feedback>

                </b-form-group>
                <b-form-group
                    :label="$t('modules.app.additional_npa.modal-info.act-acceptance')"
                    style="font-weight: bold"
                    label-for="form-input"
                    class="form-flex"
                    label-align-sm="left"
                >
                    <b-form-textarea
                        id="form-input"
                        v-model="$v.form.formTextRu.$model"
                        no-resize
                        rows="3" max-rows="4"
                        class="form-input"
                        required
                        :state="validateState('formTextRu')"
                        aria-describedby="input-2-live-feedback"
                    ></b-form-textarea>
                    <b-form-invalid-feedback
                        id="input-2-live-feedback"
                    >{{ $t('modules.app.additional_npa.modal-info.required-message') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    :label="$t('modules.app.additional_npa.modal-info.act-acceptance') + ' (каз)'"
                    style="font-weight: bold"
                    label-for="form-input"
                    class="form-flex"
                    label-align-sm="left"
                >
                    <b-form-textarea
                        id="form-input"
                        v-model="$v.form.formTextKk.$model"
                        no-resize
                        rows="3" max-rows="4"
                        class="form-input"
                        required
                        :state="validateState('formTextKk')"
                        aria-describedby="input-2-live-feedback"
                    ></b-form-textarea>
                    <b-form-invalid-feedback
                        id="input-2-live-feedback"
                    >{{ $t('modules.app.additional_npa.modal-info.required-message') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    :label="$t('modules.app.additional_npa.modal-info.note')"
                    style="font-weight: bold"
                    label-for="note-input"
                    label-align-sm="left"
                    class="form-flex"
                >
                    <b-form-textarea
                        id="note-input"
                        v-model="$v.form.note.$model"
                        no-resize
                        rows="3" max-rows="4"
                        class="form-input"
                        required
                        :state="validateState('note')"
                        aria-describedby="input-3-live-feedback"
                    ></b-form-textarea>

                    <b-form-invalid-feedback
                        id="input-3-live-feedback"
                    >{{ $t('modules.app.additional_npa.modal-info.required-message') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    :label="$t('modules.app.additional_npa.modal-info.link')"
                    label-for="link-input"
                    label-align-sm="left"
                    class="form-flex"
                >
                    <b-form-textarea
                        id="link-input"
                        v-model="$v.form.formLink.$model"
                        no-resize
                        rows="3" max-rows="4"
                        class="form-input"
                        required
                        :state="validateState('formLink')"
                        aria-describedby="input-4-live-feedback"
                    ></b-form-textarea>
                    <b-form-invalid-feedback
                        style="font-weight: bold"
                        id="input-4-live-feedback"
                    >{{ $t('modules.app.additional_npa.modal-info.required-message') }}</b-form-invalid-feedback>
                </b-form-group>
                <template v-if="isEdit">
                    <b-form-group
                        :label="$t('modules.app.additional_npa.modal-info.state')"
                        style="font-weight: bold;"
                        label-for="category-input"
                        label-align-sm="left"
                        class="form-flex"
                    >
                        <b-form-select
                            v-model="$v.form.isActive.$model"
                            :state="validateState('isActive')"
                            class="form-select"
                            disabled-field="notEnabled"
                            aria-describedby="input-5-live-feedback"
                        >
                            <option v-for="option in activeOptions" :value="option.value" :key="option.key">
                                {{$t('modules.app.additional_npa.modal-info.' + option.key)}}
                            </option>
                        </b-form-select>
                        <b-form-invalid-feedback id="input-5-live-feedback">
                            {{ $t('modules.app.additional_npa.modal-info.required-message') }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </template>
            </form>

            <template v-if="!isEdit" #modal-footer>
                <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
                    <b-button
                        variant="primary"
                        class="text-uppercase"
                        @click="addAndCloseNpa"
                        size="sm"
                    >
                        {{ $t("modules.app.additional_npa.tools.save-and-close") }}
                    </b-button>
                </div>
            </template>

            <template v-else-if="isEdit" #modal-footer>
                <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
                    <b-button
                        variant="primary"
                        class="text-uppercase"
                        @click="addAndCloseNpa"
                        size="sm"
                    >
                        {{ $t("modules.app.additional_npa.tools.save") }}
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueElementLoading from 'vue-element-loading';
import store from '../../services/store';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import axios from 'axios';

export default {
    mixins: [validationMixin],
    name: 'additionallyNpa',
    components: {
        'loading': VueElementLoading
    },
    data() {
        return {
            npaTitleList: [
                {
                    name: 'sgp'
                },
                {
                    name: 'msnp'
                },
                {
                    name: 'ip'
                },
                {
                    name: 'bp'
                },
                {
                    name: 'ib'
                },
                {
                    name: 'pr'
                }
            ],
            titleNpa: '',
            visibility: true,
            obl: null,
            region: null,
            id: null,
            isEdit: false,
            isCreate: false,
            currentCategory: '',
            isType: '',
            form: {
                title_ru: '',
                title_kz: '',
                formTextRu: '',
                formTextKk: '',
                formLink: '',
                note: '',
                isActive: null,
                currentCategoryList: []
            },
            categoryOptions: [
                { value: 'sgp' },
                { value: 'msnp' },
                { value: 'ip' },
                { value: 'bp' },
                { value: 'ib' },
                { value: 'pr' }
            ],
            activeOptions: [
                { value: null, key: 'select-state' },
                { value: true, key: 'isActive' },
                { value: false, key: 'notActive' }
            ],
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                    thStyle: { width: '1%' }
                },
                {
                    key: 'title_ru',
                    label: this.$t('modules.app.additional_npa.modal-info.title'),
                    thStyle: { width: '40%' }
                },
                {
                    key: 'form',
                    label: this.$t('modules.app.additional_npa.modal-info.act-acceptance'),
                    thStyle: { width: '35%' }
                },
                {
                    key: 'note',
                    label: this.$t('modules.app.additional_npa.modal-info.note'),
                    thStyle: { width: '21%' }
                },
                {
                    key: 'other',
                    label: '',
                    thStyle: { width: '3%' }
                }
            ],
            npaList: [],
            isFetching: false,
            titleFields: {
                ru: [
                    {
                        key: 'title_ru',
                        label: 'Заголовок НПА'
                    },
                    {
                        key: 'form',
                        label: 'Форма НПА и орган, принявший акт'
                    },
                    {
                        key: 'note',
                        label: 'Примечание'
                    }
                ],
                kk: [
                    {
                        key: 'title_ru',
                        label: 'НҚА Тақырыбы'
                    },
                    {
                        key: 'form',
                        label: 'НҚА Нысаны және актіні қабылдаған орган'
                    },
                    {
                        key: 'note',
                        label: 'Ескертпе'
                    }
                ],
                en: [
                    {
                        key: 'title_ru',
                        label: 'Header RLA'
                    },
                    {
                        key: 'form',
                        label: 'Form of RLC and the body that adopted the act'
                    },
                    {
                        key: 'note',
                        label: 'Note'
                    }
                ]
            }
        };
    },
    validations: {
        form: {
            title_ru: {
                required,
                minLength: minLength(1)
            },
            title_kz: {
                required,
                minLength: minLength(1)
            },
            formTextRu: {
                required,
                minLength: minLength(1)
            },
            formTextKk: {
                required,
                minLength: minLength(1)
            },
            formLink: {
                required,
                minLength: minLength(1)
            },
            note: {},
            isActive: {
                required
            },
            currentCategoryList: {
                required,
                minLength: minLength(1)
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        isAuthorized() {
            return store.state.user.authorized;
        },
        async getObl() {
            let region = this.region;
            try {
                const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);

                if (result.status === 200) {
                    const json = await result.json();
                    this.obl = json.obl;
                } else {
                    this.makeToast('danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса get-budget-obl', error.toString());
            }

            try {
                await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.usrId)
                    .then(response => response.json())
                    .then(json => {
                        if (json.length && json[0].code) { region = json[0].code; }
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса get-user-budget-region', error.toString());
            }
            this.region = region;
        },
        openRule(link) {
            window.open(link);
        },
        resetModal() {
            this.isEdit = false;

            this.id = null;
            this.currentCategory = this.form.currentCategoryList[0].value;

            this.form.title_ru = '';
            this.form.title_kz = '';
            this.form.formTextRu = '';
            this.form.formTextKk = '';
            this.form.formLink = '';
            this.form.note = '';
            this.form.currentCategoryList = this.form.currentCategoryList.slice(0, 1);
            this.form.isActive = null;
            this.visibility = true;

            this.$nextTick(() => {
                this.$v.$reset();
            });

            this.$refs.modal.hide();
        },
        async addAndCloseNpa() {
            if (this.isEdit === false) {
                this.form.isActive = true;
            }
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            let category = '';
            for (let i = 0; i < this.form.currentCategoryList.length; i++) {
                if (this.form.currentCategoryList.length - 1 === i) {
                    category += this.form.currentCategoryList[i].value;
                } else {
                    category += this.form.currentCategoryList[i].value + ',';
                }
            }
            const newData = {
                id: this.id,
                code: null,
                code_region: this.region,
                nameRu: this.form.title_ru,
                nameKk: this.form.title_kz,
                act_acceptance_ru: this.form.formTextRu,
                act_acceptance_kk: this.form.formTextKk,
                ref: this.form.formLink,
                description: this.form.note,
                category_act: category,
                active: this.form.isActive
            };
            if (this.visibility) {
                newData.code_region = null;
            }
            let activeOrDeactivatingDate = new Date(Date.now() - (new Date().getTimezoneOffset() * 1000 * 60)).toJSON().replace(/T/, ' ').split(' ')[0];
            activeOrDeactivatingDate = activeOrDeactivatingDate.replace(/:/g, '-');
            if (this.isEdit === true) {
                if (newData.active === false) {
                    newData.endDate = activeOrDeactivatingDate;
                }
                await this.editData(newData);
            } else {
                newData.begDate = activeOrDeactivatingDate;
                await this.addData(newData);
            }
            await this.loadData();
            this.resetModal();
        },
        openModalAndUpdateForm(data) {
            this.$refs.modal.show();
            this.isEdit = true;

            this.id = data.id;
            this.visibility = !data.code_region;
            this.form.title_ru = data.nameRu;
            this.form.title_kz = data.nameKk;
            this.form.formTextRu = data.act_acceptance_ru;
            this.form.formTextKk = data.act_acceptance_kk;
            this.form.formLink = data.ref;
            this.form.note = data.description;
            this.form.currentCategoryList = data.category_act.slice();
            this.form.isActive = data.active;
        },
        openModal() {
            this.$refs.modal.show();
        },
        async addData(newData) {
            this.isFetching = true;
            try {
                await axios.post('/api/dict/npa/save', newData);
                this.isFetching = false;
            } catch (err) {
                console.error(err);
                this.isFetching = false;
            }
        },
        deleteCategory(index) {
            const indexDelete = index + 1;
            this.form.currentCategoryList = this.form.currentCategoryList.slice(0, index).concat(this.form.currentCategoryList.slice(indexDelete));
        },
        async loadData() {
            this.npaList = [];
            this.isFetching = true;
            try {
                const response = await fetch('/api/dict/npa/region?code_region=' + this.region);
                const values = await response.json();
                values.forEach(value => {
                    if (value.category_act !== null && value.category_act.split(',').includes(this.$route.params.typeNpa.split('&')[0]) && value.active === true) {
                        const item = {
                            id: value.id,
                            code: value.code,
                            nameRu: value.nameRu,
                            nameKk: value.nameKk,
                            act_acceptance_ru: value.act_acceptance_ru,
                            act_acceptance_kk: value.act_acceptance_kk,
                            ref: value.ref,
                            description: value.description,
                            active: value.active,
                            code_region: value.code_region
                        };
                        item.abilityToEdit = value.code_region !== null;

                        item.category_act = this.categoryPreparation(value.category_act);
                        this.npaList.push(item);
                    }
                });

                this.isFetching = false;
            } catch (error) {
                console.error(error);
                this.isFetching = false;
            }
        },
        async editData(editData) {
            this.isFetching = true;
            try {
                await fetch('/api/dict/npa/save', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(editData)
                });
                this.isFetching = false;
            } catch (error) {
                console.error(error);
                this.isFetching = false;
            }
        },
        categoryPreparation(categoryAct) {
            return categoryAct.split(',').map(el => {
                if (el === 'sgp') {
                    return {
                        value: 'sgp'
                    };
                } if (el === 'msnp') {
                    return {
                        value: 'msnp'
                    };
                } if (el === 'ip') {
                    return {
                        value: 'ip'
                    };
                } if (el === 'bp') {
                    return {
                        value: 'bp'
                    };
                } if (el === 'ib') {
                    return {
                        value: 'ib'
                    };
                } if (el === 'pr') {
                    return {
                        value: 'pr'
                    };
                }
            });
        }
    },
    async created() {
        this.isType = 'preCreate';
        await this.getObl();
        this.currentCategory = this.$route.params.typeNpa.split('&')[0];
        const curNpa = this.npaTitleList.find(el => {
            if (el.name === this.$route.params.typeNpa.split('&')[0]) {
                return el;
            }
        });
        this.titleNpa = curNpa.name;
        await this.loadData();
        this.isType = 'created';
    },
    watch: {
        currentCategory: function (val) {
            let selectValue = '';
            this.categoryOptions.forEach(cat => {
                if (cat.value === val) {
                    selectValue = cat.value;
                }
            });
            const isInclude = this.form.currentCategoryList.some((el) => {
                return el.value === selectValue;
            });
            if (!isInclude) {
                this.form.currentCategoryList.push({
                    value: selectValue
                });
            }
        },
        '$route.params.typeNpa': async function (typeNpa) {
            this.npaList = [];
            if (this.isType === 'preCreate') {
                return;
            }
            this.form.currentCategoryList = [];
            this.currentCategory = typeNpa;

            const curNpa = this.npaTitleList.find(el => {
                if (el.name === typeNpa) {
                    return el;
                }
            });
            this.titleNpa = curNpa.name;
            await this.loadData();
        },
        '$i18n.locale': function () {
            this.tableFields.forEach(el => {
                this.titleFields[this.$i18n.locale].forEach(title => {
                    if (el.key === title.key) {
                        el.label = title.label;
                    }
                });
            });
        }
    },
    computed: {
        usrId() {
            if (store.state.user.sub === undefined) { return null; }
            return store.state.user.sub;
        }
    }
};
</script>

<style scoped>
.form-input, .form-select {
    min-width: 545px;
    background: #FFFFFF;
    border: 1px solid #BCD2E0;
    border-radius: 5px;
    font-size: 15px;
}
</style>